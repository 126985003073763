/* Mixins */
.form-edit-component-small .update-dialog-panel[data-v-39b11936] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  text-align: center;
  height: 240px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.form-edit-component-small .update-dialog-panel div[data-v-39b11936] {
  line-height: 50px;
}
.form-edit-component-small .update-dialog-panel div[data-v-39b11936]:first-child {
  font-weight: bolder;
  font-size: 18px;
}
.form-edit-component-small .update-dialog-panel .update-dialog-panel-loading[data-v-39b11936] {
  line-height: 120px;
}